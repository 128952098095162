import { useField } from "formik"


const TextInput = ({label, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <div className="mb-3">
            <label  className="form-label fs-6 fw-bold" htmlFor={props.name}>{label}</label>
            <div className="input-group">
                <input className="form-control" type='text' {...field} {...props} />
            </div>
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
};

export default TextInput