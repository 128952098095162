import { useField } from "formik"


const Select = ({label,children, ...props}) => {
    const [field, meta] = useField(props);


    return (
        <div className="mb-3">
            <label  className="form-label fs-6 fw-bold">{label}</label>
            <div className="input-group mb-3">
                <select className="form-select  fw-bold" {...field} {...props}>
                    {children }
                </select>
            </div>
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
}

export default Select;