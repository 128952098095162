import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";


const usersAdapter = createEntityAdapter();

const initialState = usersAdapter.getInitialState({
    users: [],
    user: JSON.parse(localStorage.getItem('irro_user')) || {},
    usersLoadingStatus: 'idle',
    userLoadingStatus: 'idle',
});

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async () => {
        const { request } = useHttp();

        const response = await request('users')

        return response;
    }
);

export const loginUser = createAsyncThunk(
    'users/loginUser',
    async ({user}) => {
        const { request } = useHttp();

        const response = await request(
            'user/login',
            'POST',
            JSON.stringify({
                user
            })
        );

        return response;
    }
);

export const logoutUser = createAsyncThunk(
    'users/logoutUser',
    async () => {
        const { request } = useHttp();

        const response = await request('user/logout');

        return response
    }

    
);

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsers.pending, state => {state.usersLoadingStatus = 'loading'})
            .addCase(fetchUsers.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.users = data;

                    state.usersLoadingStatus = 'idle'
                }
            })
            .addCase(fetchUsers.rejected, state => {state.usersLoadingStatus = 'error'})
            .addCase(loginUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(loginUser.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.user = {...data};

                    localStorage.setItem('irro_user', JSON.stringify({...data}));

                    state.userLoadingStatus = 'idle';
                }
            })
            .addCase(loginUser.rejected, state => {state.userLoadingStatus = 'error'})
            .addCase(logoutUser.pending, state => {state.userLoadingStatus = 'loading'})
            .addCase(logoutUser.fulfilled, (state, action) => {
                const {status, message, data} = action.payload;
                if (status === 'ok') {
                    state.user = {};
                    localStorage.removeItem('irro_user');
                    state.userLoadingStatus = 'idle';
                }
            })
            .addCase(logoutUser.rejected, state => {state.userLoadingStatus = 'error'})
    }
});

const { reducer } = usersSlice;

export default reducer;