import { Formik, ErrorMessage, useFormikContext } from "formik";
import * as Yup from 'yup'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { update, updateCustomer } from "../redux/slices/customersSlice";
import { addProduct, update as updateProductState, updateProduct, deleteProduct} from "../redux/slices/productsSlice";
import { addLicense, update as updateLicenseState,  updateLicense, deleteLicense, downloadLicense} from "../redux/slices/licensesSlice";
import { addYears, format, isValid, parse } from "date-fns";
import { useMemo, useState } from "react";

import { DeleteCustomer } from "./CustomersPage";
import TextInput from "../components/TextInput";
import Select from "../components/Select";
import Table from "../components/Table";
import Modal from "../components/Modal";
import DateInput from "../components/DateInpyt";
import CustomersSelect from "../components/CustomersSelect";

import { ReactComponent as TrashIcon } from '../assets/images/svg/trash.svg';
import { ReactComponent as DownloadIcon }   from '../assets/images/svg/download.svg';



const CustomerPage = () => {
    const navigate = useNavigate();
    const { customerId } = useParams();
    const [tab, setTab] = useState('products')
    const customer = useSelector(state => state.customers.customers).find(c => c.id === customerId) ?? [];
   
    if (customer.length === 0) return navigate('/customers');

    const tabContent = tab === 'products' ? <Products rest={{customerId}}/> : <Licenses  rest={{customerId}}/>;

    return (
        <>
            <div className="row mb-3">
                <CustomerForm rest={customer}/>
            </div>
            <div className="row">
                <nav>
                    <div class="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
                        <button onClick={() => setTab('products')} class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Продукти</button>
                        <button onClick={() => setTab('licenses')} class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Ліцензії</button>
                    </div>
                </nav>
                <div class="tab-content" id="myTabContent">
                    {tabContent}
                </div>
            </div>
        </>
    )
};

const Products = ({rest}) => {
    const { customerId } = rest;
    const data = useSelector(state => state.products.products).filter(p => p.customer === customerId);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                Header: 'Тип',
                accessor: 'type',
                Cell: ({row}) => {
                    const good = goods.find(g => g.type === row.original.type);

                    return good.name
                }
            },
            {
                Header: 'Ідентифікатор',
                accessor: 'details'
            },
            {
                Header: 'ФОП',
                accessor: 'fop'
            },
            {
                Header: 'КОД',
                accessor: 'okpo'
            },
            {
                Header: 'Дійсна до',
                accessor: 'date_end'
            },
            {
                Header: 'Ціна',
                accessor: 'price',
            },
            {
                Header: 'Дії',
                Cell: ({row}) => {
                    return (
                        <>
                            <TrashIcon data-bs-toggle="modal" data-bs-target="#deleteProduct"/>
                            <DeleteProduct rest={{id: row.original.id}} />
                        </>
                    )
                }
            },
        ],
        []
    );

    return (
        <>
            <div className="row row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити продукт</button>
                    </li>
                </ul>
            </div>
            <div className="row row m-0 p-0">
                <Table rest={{data, columns, search: false, paggination: false,  onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateProduct rest={{customerId, isModalOpen, data, closeModal}}/>
        </>
    )

};

const Licenses = ({rest}) => {
    const dispatch = useDispatch();
    const { customerId } = rest;
    const data = useSelector(state => state.licenses.licenses).filter(l => l.customer === customerId);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                Header: 'Дата з',
                accessor: 'date_doc',
                Cell: ({row}) => {
                    const date = format(row.original.date_doc, 'dd-MM-yyyy');

                    return (<span className="">{date}</span> );
                }
            },
            {
                Header: 'Дата по',
                accessor: 'date_end',
                Cell: ({row}) => {
                    const date = format(row.original.date_end, 'dd-MM-yyyy');

                    return (<span className="">{date}</span> )
                }
            },
            {
                Header: 'Код',
                accessor: 'okpo',
            },
            {
                Header: 'ФОП',
                accessor: 'fop',
            },
            {
                Header: 'Індетифікатор',
                accessor: 'details',
            },
            {
                Header: 'Тип',
                accessor: 'type',
                Cell: ({row}) => {
                    const good = goods.find(g => g.type === row.original.type)

                    return good.name
                }
            },
            {
                Header: 'Сума',
                accessor: 'amount',
            },
            {
                Header: 'Оплата',
                accessor: 'paid',
                id: 'paymentColumn',
                Cell: ({row}) => {
                    const paid = row.original.paid % 2;

                    return paid ? 'Оплачено' : 'Нема';
                }
            },
            {
                Header: 'Владік',
                accessor: 'paid',
                id: 'vladikColumn',
                Cell: ({row}) => {
                    const paid = row.original.paid >> 1;

                    return paid ? 'Оплачено' : 'Нема';
                }
            },
            {
                Header: 'Дії',
                Cell: ({row}) => {
                    const { details, date_end, type, id } = row.original;

                    return (
                       <div className="row p-0 m-0 text-center align-items-center">
                            <div onClick={() => dispatch(downloadLicense({license: {details, date_end, type: Number(type)}}))} className="col p-0 m-1" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Disabled popover">
                                <DownloadIcon/>
                            </div>
                            <div className="col p-0 m-1">
                                <TrashIcon data-bs-toggle="modal" data-bs-target="#deleteProduct"/>
                                <DeleteLicense rest={{id}} />
                            </div>
                       </div>
                    )
                }
            },
        ],
        []
    )

    return (
        <>
            <div className="row row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити ліцензію</button>
                    </li>
                </ul>
            </div>
            <div className="row row m-0 p-0">
                <Table rest={{data: data, columns, search: false, paggination: false, onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateLicense rest={{customerId, isModalOpen, data, closeModal}}/>
        </>
    )
    
}

export const CreateLicense = ({rest}) => {
    const dispatch = useDispatch();
    const goods = useSelector(state => state.appInfo.goods);
    

    const {customerId = null, isModalOpen, closeModal, data = [] } = rest;

    const license = data.find(l => l.id === isModalOpen) || [];

    const { id = null, customer = null, type = null, date_doc = '', date_end = '',  details = null,  amount = null, paid = null} = license;

    const products  = useSelector(state => state.products.products)

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Нова ліцензія</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ customer: customerId ?? customer ?? '', date_doc: date_doc ?? '', date_end: date_end ?? '', type: type ?? '',  details: details ?? '',  price:  amount ?? 0, paid: paid ? paid % 2 :'0', paidSec: paid ?  paid >> 1 : '0'}}
                        validationSchema={validationSchemaLicenses}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);

                            if (values.customer) {
                                
                            }
                        
                            if (id) {
                                dispatch(updateLicense({license: {...values, id, paid: (Number(values.paid) + Number(values.paidSec) * 2), customer: customer ?? values.customer}}))
                                // .then(data => {
                                //     if (data.payload.status === 'ok' && data.payload.data) {
                                //         const id = data.payload.data.id;
                                //         dispatch(updateLicenseState({id}));
                                //     }
                                // })
                            } else {
                                dispatch(addLicense({license: {...values, paid: (Number(values.paid) + Number(values.paidSec) * 2), customer: customerId ?? values.customer}}))
                                // .then(data => {
                                //     if (data.payload.status === 'ok' && data.payload.data) {
                                //         const id = data.payload.data.id;
                                //         dispatch(updateLicenseState({id}));
                                //     }
                                // })
                            }
                            
                            resetForm();
                            setSubmitting(false);
                            closeModal();
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
                            const customerFilter = values.customer || customerId || customer;
                            const filteredProducts = customerFilter ? products.filter(p => p.customer === customerFilter) : [];

                            return (
                            <form onSubmit={handleSubmit} className="row">
                                <div className="col-6">
                                    <DateInput
                                        label="Дата з"
                                        name="date_doc"
                                        onChange={(val) => {
                                        setFieldValue('date_doc', val);
                                        const dateEnd = addYears(val, 1);
                                        setFieldValue('date_end', dateEnd);
                                        }}
                                    />
                                </div>
                                <div className="col-6">
                                    <DateInput
                                        label="Дата по"
                                        name="date_end"
                                        onChange={(val) => setFieldValue('date_end', val)}
                                    />
                                    </div>
                                <div className="col-6">
                                    {!customerId &&  !customer && 
                                        <CustomersSelect
                                            label="Клієнт"
                                            id="customer"
                                            name="customer"
                                        />
                                    }
                                </div>
                                <div className="col-6">
                                    <Select
                                        label="Індетифікатор"
                                        id="details"
                                        name="details"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const type = filteredProducts.find(p => p.details === selectedValue);
                                            const typeValue = type ? goods.find(g => g.type === type.type) : '';
                                            setFieldValue('details', selectedValue);
                                            setFieldValue('type', typeValue?.type);
                                            setFieldValue('price', typeValue?.price);
                                        }}
                                    >
                                        <option disabled value="">
                                            Виберіть ідентифікатор
                                        </option>
                                        {filteredProducts.map((item) => {
                                            const { details, id } = item;
                                            return (
                                                <option key={id} value={details}>
                                                    {details}
                                                </option>
                                            );
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <Select
                                        label="Тип"
                                        id="type"
                                        name="type"
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            const typeValue = goods.find(g => g.type === selectedValue);
                                            setFieldValue('type', typeValue?.type);
                                            setFieldValue('price', typeValue?.price);
                                        }}
                                    >
                                        {values.details &&
                                            goods.map((item) => {
                                                    const { type, id, name } = item;
                                                    return (
                                                        <option key={id} value={type} >
                                                            {name}
                                                        </option>
                                                    );
                                            })
                                        }
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput label="Ціна" id="price" name="price" />
                                </div>
                                <div className="col-6">
                                    <Select
                                        label='Оплата'
                                        id="paid"
                                        name='paid'
                                    >
                                        <option value="0">Ні</option>
                                        <option value="1">Так</option>
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <Select
                                        label='Оплата Владік'
                                        id="paidSec"
                                        name='paidSec'
                                    >
                                        <option value="0">Ні</option>
                                        <option value="1">Так</option>
                                    </Select>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={closeModal}
                                    >
                                        Закрити
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-atuo me-2"
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? 'Збереження...' : 'Зберегти'}
                                    </button>
                                </div>
                            </form>
                            );
                        }}
                        </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

const DeleteProduct = ({rest}) => {
    const dispatch = useDispatch();
    const {id} = rest;

    const handleClick = () => {
        dispatch(deleteProduct({id}))
    }

    return (
        <div>
            <div className="modal fade" id="deleteProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Видалення продукту</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Ви дійсно хочете видалити продукт?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ні</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal"  onClick={handleClick}>Так</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const DeleteLicense = ({rest}) => {
    const dispatch = useDispatch();
    const {id} = rest;

    const handleClick = () => {
        dispatch(deleteLicense({id}))
    }

    return (
        <div>
            <div className="modal fade" id="deleteProduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Видалення ліцензії</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Ви дійсно хочете видалити ліцензію?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ні</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal"  onClick={handleClick}>Так</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreateProduct = ({rest}) => {
    const dispatch = useDispatch();
    const {customerId, isModalOpen, closeModal, data = [], } = rest;
    const goods = useSelector(state => state.appInfo.goods);

    const product = data.find(d => d.id === isModalOpen) || [];

    const { id = null, type = null,  details = null, fop = null, okpo = null, price= null} = product;
    

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Новий продукт</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ type: type ?? goods[0].type ?? '0',  details: details ?? '', fop: fop ?? '', okpo: okpo ?? '', price:  price ?? goods[0].price ?? 0}}
                        validationSchema={validationSchemaProducts}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                        
                            if (id) {
                                dispatch(updateProduct({product: {...values, id, customer: customerId}}))
                                .then(data => {
                                    if (data.payload.status === 'ok' && data.payload.data) {
                                        const id = data.payload.data
                                        dispatch(updateProductState({...values, customer: customerId, id}));
                                    }
                                })
                            } else {
                                dispatch(addProduct({product: {...values, customer: customerId}}))
                                .then(data => {
                                    if (data.payload.status === 'ok' && data.payload.data) {
                                        const id = data.payload.data
                                        dispatch(updateProductState({...values, customer: customerId, id}));
                                    }
                                })
                            }
                            
                            resetForm();
                            setSubmitting(false);
                            closeModal();
                        }}
                    >
                        {({ handleSubmit, isSubmitting, setFieldValue, values }) => (
                            <form onSubmit={handleSubmit} className="row ">
                                <div className="col-6">
                                    <Select
                                        label='Тип'
                                        id="type"
                                        name='type'
                                        onChange={(e) => {
                                            const selectedType = e.target.value;
                                            setFieldValue('type', selectedType);

                                            const selectedItem = goods.find((item) => item.type === selectedType);
                                            if (selectedItem) {
                                                setFieldValue('price', selectedItem.price);
                                            }
                                        }}
                                    >
                                        {goods.map((item, index) => {
                                            const {id, name, type} = item;

                                            return (
                                                <option key={id} value={type}>{name}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Індетифікатор'
                                        id="details"
                                        name='details'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='ФОП'
                                        id="fop"
                                        name='fop'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='КОД'
                                        id="okpo"
                                        name='okpo'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Ціна'
                                        id="price"
                                        name='price'
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                    <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Збереження..." : "Зберегти"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    );
}

const CustomerForm = ({rest}) => {
    const dispatch = useDispatch();
    const offices = useSelector(state => state.appInfo.offices);
    const { id, 'name': companyName = '', 'fullname': manager = '', 'adress':address = '', phone = '', office = '0', url = '' } = rest;

    return (
        <div className="row">
            <Formik
                initialValues={{ id: id, companyName: companyName,  address: address, manager: manager, phone: phone, office: office, url: url}}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    dispatch(updateCustomer({customer: values}))
                    .then(data => {
                        if (data.payload.status === 'ok') {
                            dispatch(update(values))
                        }
                    })
                    setSubmitting(false);
                }}
            >
                {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="row ">
                        <div className="col-2">
                            <TextInput 
                                label='ID'
                                id="id"
                                name='id'
                                disabled
                            />
                        </div>
                        <div className="col-10">
                            <TextInput 
                                label='Назва об`єкта'
                                id="companyName"
                                name='companyName'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Адреса'
                                id="address"
                                name='address'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Відповідальна особа'
                                id="manager"
                                name='manager'
                            />
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='Телефон'
                                id="phone"
                                name='phone'
                            />
                        </div>
                        <div className="col-6">
                            <Select
                                 label='Офіс'
                                 id="office"
                                 name='office'
                            >
                                <option value="0">Немає</option>
                                {offices.map(item => {
                                    const {id, name, type} = item;

                                    return (
                                        <option key={id} value={type}>{name}</option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="col-6">
                            <TextInput 
                                label='URL'
                                id="url"
                                name='url'
                            />
                        </div>
                        <div className="col-12">
                            <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                {isSubmitting ? "Збереження..." : "Зберегти зміни"}
                            </button>
                            <div  className="btn btn-danger w-atuo  me-2"  data-bs-toggle="modal" data-bs-target="#deleteCustomer">Видалити клієнта</div>
                            <div  className="btn btn-primary w-atuo">Перенести ліцензії</div>
                        </div>
                    </form>
                )}

            </Formik>
            <DeleteCustomer rest={{id}}/>
        </div>
    )
};

const validationSchemaLicenses = Yup.object().shape({
    date_doc: Yup.string()
        .required('Дата початку обов`язкова'),
    date_end: Yup.string()
        .required('Дата закінчення обов`язкова'),
    customer: Yup.string()
        .required('Оберіть користувача!'),
    details: Yup.string()
        .required('Індетифікатор є обовязковим!'),
    type: Yup.string()
        .required('Індетифікатор є обовязковим!')
});

export const validationSchema = Yup.object().shape({
    companyName: Yup.string()
        .required('Назва об`єкта обовязкова!')
})
export const validationSchemaProducts = Yup.object().shape({
    details: Yup.string()
        .required('Індетифікатор є обовязковим!')
})

export default CustomerPage;