import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";

const appInfoAdapter = createEntityAdapter();

const initialState = appInfoAdapter.getInitialState({
    offices: [],
    goods: [],
    appInfoLoadingStatus: 'idel'
});

export const fetchAppInfo = createAsyncThunk(
    'appInfo/fetchAppInfo',
    async () => {
        const { request } = useHttp();

        const response = await request(`appInfo`);

        return response;
    }
);

const appInfoSlice = createSlice({
    name: 'appInfo',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAppInfo.pending, state => { state.appInfoLoadingStatus = 'loading' })
            .addCase(fetchAppInfo.fulfilled, (state, actions) => {
                const data = actions.payload.data;
                const { offices, goods } = data;

                state.offices = offices;
                state.goods = goods;

                state.appInfoLoadingStatus = 'idle'
            })
            .addCase(fetchAppInfo.rejected, state => {state.appInfoLoadingStatus = 'error'})
            .addDefaultCase(() => {})
    }
});

const { reducer} = appInfoSlice;

export default reducer;