import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";


const PrivateRoute = ({ element: Component, ...rest }) => {
    const isAuthenticated = useSelector(state => state.users.user);
    return isAuthenticated ? (
        Component
    ) : (
        <Navigate to="/login" />
    );
}

export default PrivateRoute;