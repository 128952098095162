import { combineReducers } from "@reduxjs/toolkit";
import licenses from './licensesSlice';
import appInfo from './appInfoSlice';
import customers from './customersSlice';
import products from './productsSlice';
import users from './usersSlice';

const rootReducer = combineReducers({
    licenses, appInfo, customers, products, users
});

export default rootReducer;