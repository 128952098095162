import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";

const productsAdapter = createEntityAdapter();

const initialState = productsAdapter.getInitialState({
    products: [],
    productsLoadingStatus: 'idle',
    productLoadingStatus: 'idle',
});

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const { request } = useHttp();

        const response = await request('products');

        return response;
    }
);

export const addProduct = createAsyncThunk(
    'products/addProduct',
    async ({product}) => {
        const { request } = useHttp();

        const response = await request( 
            'product/add',
            'POST',
            JSON.stringify({
                product
            })
        );

        return response;
    }
);

export const updateProduct = createAsyncThunk(
    'products/updateProduct',
    async ({product}) => {
        const { request } = useHttp();

        const response = await request(
            'product/update',
            'POST',
            JSON.stringify({
                product
            })
        )

        return response
    }
);

export const deleteProduct = createAsyncThunk(
    'products/deleteProduct',
    async ({id}) => {
        const { request } = useHttp();

        const response = request(
            'product/delete',
            'POST',
            JSON.stringify({
                id
            })
        );

        return response;
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        update(state, action) {
            const { id, ...data } = action.payload;
            const index = state.products.findIndex(c => c.id === id.toString());

            if (index !== -1) {
                state.products[index] = { ...data, id: id.toString()};
            }
        },
    },
    extraReducers: (builder) => 
        builder
            .addCase(fetchProducts.pending, state => {state.productsLoadingStatus = 'loading'})
            .addCase(fetchProducts.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;
                if (status === 'ok') {
                    state.products = data;

                    state.productsLoadingStatus = 'idle';
                }
            })
            .addCase(fetchProducts.rejected, state => {state.productsLoadingStatus = 'error'})
            .addCase(addProduct.pending, state => {state.productLoadingStatus = 'loading'})
            .addCase(addProduct.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {
                    state.products.push({ id: data.toString(), ...data});

                    state.productLoadingStatus = 'idle';
                }
            })
            .addCase(addProduct.rejected, state => {state.productLoadingStatus = 'error'})
            .addCase(updateProduct.pending, state => {state.productLoadingStatus = 'loading'})
            .addCase(updateProduct.fulfilled, (state, action) => {
                const { message = '', status = '', data = [] } = action.payload;

                if (status === 'ok' && data) {
                    state.products.push({ id: data.toString(), ...data});

                    state.productLoadingStatus = 'idle';
                }
            })
            .addCase(updateProduct.rejected, state => {state.productLoadingStatus = 'error'})
            .addCase(deleteProduct.pending, state => {state.productLoadingStatus = 'loading'})
            .addCase(deleteProduct.fulfilled, (state, action) => {
                const { message, status, data } = action.payload;
                if (status === 'ok' && data) {
                    const product = state.products.filter(c => c.id !== data);

                    state.products = product;
                }

                state.customerLoadingStatus = 'idle';
            })
            .addCase(deleteProduct.rejected, state => {state.productLoadingStatus = 'error'})
            .addDefaultCase(() => {})
});

const { reducer, actions } = productsSlice;

export default reducer;

export const { update } = actions;