import React, { useState } from 'react';
import { useTable, useGlobalFilter, useSortBy, usePagination } from 'react-table';

const GlobalFilter = ({ filter, setFilter }) => {
    return (
        <div className="row">
            <div className="mb-3">
                <div className="input-group">
                    <input 
                        className="form-control" 
                        name='search' 
                        type='text'
                        value={filter || ''}
                        onChange={e => setFilter(e.target.value || undefined)}
                        placeholder="Пошук"
                    />
                </div>
            </div>
        </div>
    );
};

const Table = ({ rest }) => {
    const { columns, data, onDoubleClick = () => {}, search = true, paggination = true } = rest;
    const [globalFilter, setGlobalFilter] = useState('');

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
        setGlobalFilter: setTableGlobalFilter,
    } = useTable(
        { 
            columns, 
            data,
            initialState: { pageIndex: 0, pageSize: 100 },
        },
        useGlobalFilter, 
        useSortBy,
        usePagination     
    );

    const handleFilterChange = value => {
        setGlobalFilter(value);
        setTableGlobalFilter(value);
    };

    const renderSearch = search ? <GlobalFilter filter={globalFilter} setFilter={handleFilterChange} /> : null;

    return (
        <>
            {renderSearch}
            <table {...getTableProps()} className="table table-hover table-bordered mt-2">
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                key={row.original.id}
                                onDoubleClick={() => onDoubleClick(row.original.id)}
                            >
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} key={cell.column.id}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {paggination && 
              <div className="row justify-content-center mt-3">
                <div className="col-auto">
                    <div className="d-flex justify-content-center align-items-center">
                        <button
                            className="btn btn-secondary me-2"
                            onClick={() => gotoPage(0)}
                            disabled={!canPreviousPage}
                        >
                            {'<<'}
                        </button>
                        <button
                            className="btn btn-secondary me-2"
                            onClick={() => previousPage()}
                            disabled={!canPreviousPage}
                        >
                            {'<'}
                        </button>
                        <div className="d-flex align-items-center me-2" style={{ minWidth: '120px', textAlign: 'center' }}>
                            <span className="me-2">Сторінка</span>
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>
                        </div>
                        <button
                            className="btn btn-secondary me-2"
                            onClick={() => nextPage()}
                            disabled={!canNextPage}
                        >
                            {'>'}
                        </button>
                        <button
                            className="btn btn-secondary"
                            onClick={() => gotoPage(pageOptions.length - 1)}
                            disabled={!canNextPage}
                        >
                            {'>>'}
                        </button>
                        <select
                            className="form-select ms-2"
                            value={pageSize}
                            onChange={e => setPageSize(Number(e.target.value))}
                        >
                            {[100, 150, 200, 250].map(size => (
                                <option key={size} value={size}>
                                    Показати {size}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            }

        </>
    );
};

export default Table;
