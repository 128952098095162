import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../components/Table";
import {  useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { validationSchema } from "./CustomerPage";
import { addCustomer, deleteCustomer, update } from "../redux/slices/customersSlice";

import TextInput from "../components/TextInput";
import Select from "../components/Select";

import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'
import Modal from "../components/Modal";


const CustomersPage = () => {
    const navigate = useNavigate();
    const data = useSelector(state => state.customers.customers);
    const offices = useSelector(state => state.appInfo.offices);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleDoubleClick = (id) => {
        return navigate(`/customer/${id}`)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
            },
            {
                Header: 'Робоча назва',
                accessor: 'name',
            },
            {
                Header: 'Офіц.Назва',
                accessor: 'fullname',
            },
            {
                Header: 'Адреса',
                accessor: 'adress',
            },
            {
                Header: 'Телефон',
                accessor: 'phone',
            },
            {
                Header: 'УРЛ',
                accessor: 'url',
            },
            {
                Header: 'Офіс',
                accessor: 'office',
                Cell: ({row}) => {
                    const office = offices.find(o => o.type === row.original.office);

                    return office ? office.name : 'Нема';
                }
            },
            {
                Header: 'Дії',
                Cell: ({ row }) => {
                    return (
                        <>
                            <TrashIcon data-bs-toggle="modal" data-bs-target="#deleteCustomer"/>
                            <DeleteCustomer rest={{id: row.original.id}} />
                        </>
                    );
                }
            },
        ],
        []
    )

    return (
        <>
            <div className="row m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити клієнта</button>
                    </li>
                </ul>
            </div>
            <div className="row m-0 p-0">
                <Table rest={{data, columns, onDoubleClick: handleDoubleClick}}/>
            </div>
            <CreateCustomerModal rest={{ isModalOpen, closeModal }}/>    
        </>
    )
};

export const DeleteCustomer = ({rest}) => {
    const dispatch = useDispatch();
    const {id} = rest;

    const handleClick = () => {
        dispatch(deleteCustomer({id}))
    }

    return (
        <div>
            <div className="modal fade" id="deleteCustomer" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="staticBackdropLabel">Видалення клієнта</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            Ви дійсно хочете видалити клієнта?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Ні</button>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal"  onClick={handleClick}>Так</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const CreateCustomerModal = ({rest}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const offices = useSelector(state => state.appInfo.offices);
    
    const { isModalOpen, closeModal } = rest;

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">Новий клієнт</h1>
                        <button type="button" className="btn-close" onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                    <Formik
                        initialValues={{ companyName: '',  address: '', manager: '', phone: '', office: '', url: ''}}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            dispatch(addCustomer({customer: values}))
                            .then(data => {
                                if (data.payload.status === 'ok' && data.payload.data) {
                                    const id = data.payload.data

                                    dispatch(update({...values, id}));
                                    closeModal()
                                    return navigate(`/customer/${id}`);
                                }
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                        }}
                    >
                        {({ handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} className="row ">
                                <div className="col-6">
                                    <TextInput 
                                        label='Назва об`єкта'
                                        id="companyName"
                                        name='companyName'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Адреса'
                                        id="address"
                                        name='address'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Відповідальна особа'
                                        id="manager"
                                        name='manager'
                                    />
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='Телефон'
                                        id="phone"
                                        name='phone'
                                    />
                                </div>
                                <div className="col-6">
                                    <Select
                                        label='Офіс'
                                        id="office"
                                        name='office'
                                    >
                                        <option value="0">Немає</option>
                                        {offices.map(item => {
                                            const {id, name, type} = item;

                                            return (
                                                <option key={id} value={type}>{name}</option>
                                            )
                                        })}
                                    </Select>
                                </div>
                                <div className="col-6">
                                    <TextInput 
                                        label='URL'
                                        id="url"
                                        name='url'
                                    />
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary me-2" onClick={closeModal}>Закрити</button>
                                    <button  type="submit" className="btn btn-primary w-atuo me-2" disabled={isSubmitting}>
                                        {isSubmitting ? "Збереження..." : "Зберегти"}
                                    </button>
                                </div>
                            </form>
                        )}
                    </Formik>
                    </div>     
                </div>
            </div>
        </Modal>
    )
}

export default CustomersPage;