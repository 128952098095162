import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateInput = ({ label, ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props); 

    return (
        <div className="mb-3">
            <label className="form-label fs-6 fw-bold" htmlFor={props.name}>{label}</label>
            <div className="input-group">
                <DatePicker
                    className="form-control"
                    selected={field.value}
                    onChange={(val) => setFieldValue(field.name, val)}
                    dateFormat="yyyy-MM-dd" 
                    {...props}
                />
            </div>
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div>
            ) : null}
        </div>
    );
};

export default DateInput;
