import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";


import { ReactComponent as LogoutIcon } from '../assets/images/svg/logout.svg';
import { logoutUser } from "../redux/slices/usersSlice";

const Header = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.users.user);console.log(user);
    if (Object.keys(user).length === 0) return null;

    return (
        <nav className="navbar  navbar-expand-lg">
            <div className="container-fluid">
                <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <NavLink
                                to='/'
                                className={({isActive}) => 
                                    isActive ? "nav-link active" : "nav-link"
                                }
                            >
                                Ліцензії
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to='/customers'
                                className={({isActive}) => 
                                    isActive ? "nav-link active" : "nav-link"
                                }
                            >
                                Клієнти
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li onClick={() => dispatch(logoutUser())} className="nav-item">
                            <LogoutIcon/>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Header;