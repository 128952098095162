import { useNavigate } from "react-router-dom";

export const useHttp = () => {
    const _apiBase = process.env.NODE_ENV === 'production' ? '/api/v1/' : 'https://cocktail/licensing/api/v1/';

    const request = async (url, method = 'GET', body = null, headers = {'Content-Type': 'application/json'}, responseType = 'json', ) => {
        try {
            const response = await fetch(_apiBase + url, {method, body, headers, credentials: 'include', });
            if (!response.ok) {
                if (response.status === 401) {
                    localStorage.removeItem('irro_user');
                    window.location.href = '/login';
                }
                let error = new Error(`Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.`);
                throw error;
            }

            if (responseType === 'blob') {
                return response.blob();
            }

            return response.json();
        } catch (e) {
            let error = new Error('Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.');
            error.details = {
                message: 'Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.',
                error: 'Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.',
                type: 'any',
            };
            throw error;
        }
    };

    return { request };
};