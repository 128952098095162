import { useDispatch, useSelector } from "react-redux";
import { useMemo, useState } from "react";
import { format } from 'date-fns';
import { CreateLicense, DeleteLicense } from "./CustomerPage";
import { downloadLicense } from "../redux/slices/licensesSlice";

import Table from "../components/Table";


import {ReactComponent as TrashIcon} from '../assets/images/svg/trash.svg'
import { ReactComponent as DownloadIcon }   from '../assets/images/svg/download.svg';



const LicensesPage = ({}) => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.licenses.licenses);
    const offices = useSelector(state => state.appInfo.offices);
    const goods = useSelector(state => state.appInfo.goods);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const columns = useMemo(
        () => [
            {
                Header: '№',
                id: 'index',
                Cell: ({ row: { index } }) => index + 1, 
            },
            {
                Header: 'Дата з',
                accessor: 'date_doc',
                Cell: ({row}) => {
                    const date = format(row.original?.date_doc, 'dd-MM-yyyy');

                    return (<span className="">{date}</span> );
                }
            },
            {
                Header: 'Дата по',
                accessor: 'date_end',
                Cell: ({row}) => {
                    const date = format(row.original?.date_end, 'dd-MM-yyyy');

                    return (<span className="">{date}</span> )
                }
            },
            {
                Header: 'Клієн',
                accessor: 'name',
            },
            {
                Header: 'Офіц.Назва',
                accessor: 'fullname',
            },
            {
                Header: 'Код',
                accessor: 'okpo',
            },
            {
                Header: 'ФОП',
                accessor: 'fop',
            },
            {
                Header: 'УРЛ',
                accessor: 'url',
                width: 200
            },
            {
                Header: 'Адреса',
                accessor: 'adress',
                width: 200
            },
            {
                Header: 'Телефон',
                accessor: 'phone',
            },
            {
                Header: 'Індетифікатор',
                accessor: 'details',
            },
            {
                Header: 'Тип',
                accessor: 'type',
                Cell: ({row}) => {
                    const good = goods.find(g => g.type === row.original?.type)

                    return good.name
                }
            },
            {
                Header: 'Офіс',
                accessor: 'office',
                Cell: ({ row }) => {
                    const office = offices.find(o => o.type === row.original?.office);
                    return office ? office.name : 'Нема';
                }
            },
            {
                Header: 'Сума',
                accessor: 'amount',
            },
            {
                Header: 'Оплата',
                accessor: 'paid',
                id: 'paymentColumn',
                Cell: ({row}) => {
                    const paid = row.original?.paid % 2;

                    return paid ? 'Оплачено' : 'Нема';
                }
            },
            {
                Header: 'Владік',
                accessor: 'paid',
                id: 'vladikColumn',
                Cell: ({row}) => {
                    const paid = row.original?.paid >> 1;

                    return paid ? 'Оплачено' : 'Нема';
                }
            },
            {
                Header: 'Дії',
                Cell: ({row}) => {
                    const { details, date_end, type, id } = row.original;
                    return (
                        <div className="row p-0 m-0 text-center align-items-center">
                                <div onClick={() => dispatch(downloadLicense({license: {details, date_end, type: Number(type)}}))} className="col w-25 p-0 m-1" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-content="Disabled popover">
                                    <DownloadIcon/>
                                </div>
                                <div className="col w-25 p-0 m-1">
                                    <TrashIcon data-bs-toggle="modal" data-bs-target="#deleteProduct"/>
                                    <DeleteLicense rest={{id}} />
                                </div>
                        </div>
                    )
                }
            },
        ],
        []
    )

    return (
        <>
            <div className="row  m-0 p-0">
                <ul className="nav m-2">
                    <li className="nav-item">
                        <button type="button" className="btn btn-primary" onClick={openModal} >Створити ліцензію</button>
                    </li>
                </ul>
            </div>
            <div className="row  m-0 p-0">
                <Table rest={{data, columns, onDoubleClick: setIsModalOpen}}/>
            </div>
            <CreateLicense rest={{isModalOpen, data, closeModal}}/>
        </>
    )
};

export default LicensesPage;