import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hooks/http.hook";

const customersAdapter = createEntityAdapter();

const initialState = customersAdapter.getInitialState({
    customers: [],
    customersLoadingStatus: 'idle',
    customerLoadingStatus: 'idle',
});

export const fetchCustomers = createAsyncThunk(
    'customers/fetchCustomers',
    async () => {
        const { request } = useHttp();

        const response = await request('customers');

        return response;
    }
);

export const updateCustomer = createAsyncThunk(
    'customers/updateCustomer',
    async ({ customer }) => {
        const { request } = useHttp();

        const response = await request(
            'customer/update',
            'POST',
            JSON.stringify({
                customer
            })
        );

        return response;
    }
);

export const addCustomer = createAsyncThunk(
    'customers/addCustomer',
    async ({customer}) => {
        const { request } =  useHttp();

        const response = await request(
            'customer/add',
            'POST',
            JSON.stringify({
                customer
            })
        );

        return response;
    }
);

export const deleteCustomer = createAsyncThunk(
    'costumers/deleteCostumers',
    async ({id}) => {
        const { request } = useHttp();

        const response = await request(
            'customer/delete',
            'POST',
            JSON.stringify({
                id
            })
        );

        return response;
    }
)

const customersSlice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        update(state, action) {
            const { id, companyName = '', address = '', manager = '', ...data } = action.payload;
            const index = state.customers.findIndex(c => c.id === id.toString());

            if (index !== -1) {
                state.customers[index] = { ...data, id: id.toString(), adress: address, fullname: manager, name: companyName };
            }
        },
        delete(state, action) {
            const id = action.payload;

            const costumers = state.costumers.filter(c => c.id !== id);

            state.costumers = costumers;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomers.pending, state => { state.customersLoadingStatus = 'loading'; })
            .addCase(fetchCustomers.fulfilled, (state, action) => {
                const { message, status, data } = action.payload;

                state.customers = data;

                state.customersLoadingStatus = 'idle';
            })
            .addCase(fetchCustomers.rejected, state => { state.customersLoadingStatus = 'error'; })
            .addCase(updateCustomer.pending, state => { state.customerLoadingStatus = 'loading'; })
            .addCase(updateCustomer.fulfilled, (state, action) => {
                const { message, status, data } = action.payload;
                if (status === 'ok') {
                }

                state.customerLoadingStatus = 'idle';
            })
            .addCase(updateCustomer.rejected, state => { state.customerLoadingStatus = 'error'; })
            .addCase(addCustomer.pending, state => { state.customerLoadingStatus = 'loading'; })
            .addCase(addCustomer.fulfilled, (state, action) => {
                const { message, status, data } = action.payload;
                if (status === 'ok') {
                    state.customers.push({id: data.toString()});
                }

                state.customerLoadingStatus = 'idle';
            })
            .addCase(addCustomer.rejected, state => { state.customerLoadingStatus = 'error'; })
            .addCase(deleteCustomer.pending, state => { state.customerLoadingStatus = 'loading'; })
            .addCase(deleteCustomer.fulfilled, (state, action) => {
                const { message, status, data } = action.payload;
                if (status === 'ok' && data) {
                    const customers = state.customers.filter(c => c.id !== data);

                    state.customers = customers;
                }

                state.customerLoadingStatus = 'idle';
            })
            .addCase(deleteCustomer.rejected, state => { state.customerLoadingStatus = 'error'; })
            .addDefaultCase(() => {});
    }
});

const { reducer, actions } = customersSlice;

export default reducer;

export const { update } = actions;
