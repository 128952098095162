import { motion, AnimatePresence } from 'framer-motion';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <AnimatePresence mode='wait'> 
      {isOpen && (
        <motion.div
          className="modal-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
        >
            <motion.div
                className="modal-content"
                initial={{ y: "-100vh" }}
                animate={{ y: 0 }}
                exit={{ y: "100vh" }}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;